<template>
  <subcategory
    subcategoryName="Productivity"
    :articles="articles"
  ></subcategory>
</template>
<script charset="utf-8">
import subcategory from "@/components/subcategory.vue";

export default {
  components: {
    subcategory,
  },
  data() {
    return {
      articles: [
        {
          name: "Typing faster",
          description:
            "You might be using your keyboard everyday in a wrong way. Here is how you could take advantage of it. This will allow you to get a lot of work done faster and improve your typing experience.",
          sneakPic: require("@/assets/article-sneak-pics/typing-faster.jpg"),
          sneakPicAlt: "keyboard",
          link: "/Articles/Productivity/typing-faster",
        },
      ],
    };
  },
};
</script>
